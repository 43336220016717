<template>
  <div class="mt-[22px] sm:mt-[38px] relative">
    <span class="text-lg font-raleway pb-[13px] block">Enter the delivery ZIP Code</span>
    <div class="relative">
      <div class="relative field">
        <Field
          :name="'zip' + '-' + index"
          :rules="[required]"
          class="w-full border rounded-small shadow-gray pt-4 pb-[13px] px-[18px] h-[49px] sm:h-[53px] text-[19px] sm:text-xl border-black placeholder:text-silver"
          placeholder="Delivery ZIP Code"
          v-model="internalValue"
          @focus="focusField"
          @bind="focusField"
        />
        <span
          :class="isFocused ? 'opacity-100 z-[1]' : ' opacity-0 z-0'"
          class="absolute bg-white -top-2 left-4 px-1 transition-all duration-75 ease-linear text-sm"
          >Delivery ZIP Code</span
        >
      </div>
      <span class="text-red-500 text-xs pt-1 block">{{ errors['zip' + '-' + index] }}</span>
      <span v-if="zipErr.length" class="text-red-500 text-xs pt-1 block">{{ zipErr }}</span>
      <IconCheck v-if="checkZip" class="absolute top-4 sm:top-5 right-[22px] w-4 sm:w-max" />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import IconCheck from '@components/icons/IconCheck.vue'
import { Field } from 'vee-validate'
import { required } from '@utills/helpers/validation'
import {useDebounceFn} from "@vueuse/core";
import {useSignUpCartsStore} from "@stores/sign-up-cart";
export default defineComponent({
  components: { IconCheck, Field },
  props: {
    zipCode: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    checkZip: {
      type: Boolean,
      required: true
    }
  },
  setup(props, {emit}) {
    const zipErr = ref('');
    const validateZip = (val: any) => {
      if (val.length >= 5) {
        if (val.length > 5) {
          zipErr.value = 'Please contact our office 718.517.8310 to set up a subscription for this ZIP code';
        } else {
          zipErr.value = '';
        }
      } else {
        zipErr.value = '';
      }
    };

    const internalValue = computed({
      get: () => props.zipCode,
      set: (val) => {
        validateZip(val)
        debouncedFn(val)
      }
    })

    const isFocused = ref(false)

    const { setShipment } = useSignUpCartsStore();

    function focusField() {
      isFocused.value = true
    }

    const debouncedFn = useDebounceFn(async (val) => {
      emit('update:zipCode', val)
      emit('update:modelValue', val)
      await setShipment(val)
    }, 500)

    return {
      isFocused,
      focusField,
      required,
      internalValue,
      zipErr
    }
  }
})
</script>
