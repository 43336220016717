<template>
  <div>
    <div v-if="index >= 1" class="flex justify-between">
      <h5 class="text-[19px]/[22px] sm:text-[27px]/[31px] font-semibold mt-[17px] mb-[13px]">
        Subscription #{{ index + 1 }}
      </h5>
      <button @click.prevent="removeSubscription" class="text-lg font-bold text-vivid-purple">
        Remove
      </button>
    </div>
    <DeliveryZipCode
        :zipCode="subscription.zipCode"
        :errors="errors"
        @update:modelValue="updateZipCode"
        :index="index"
        :checkZip="dataZip?.status"
        @updateZipCode="updateZipCode"
    />
    <span v-if="dataZip?.err && zipVal.length <= 5" class="text-red-500 text-xs"
    >Please contact our office 718.517.8310 to set up a subscription for this ZIP code</span
    >
    <div
        :class="dataZip?.status ? 'opacity-100' : 'opacity-50 pointer-events-none'"
        class="transition-all duration-300 ease-linear"
    >
      <SubscriptionPreferance
          :choosePlan="choosePlan"
          :allIssues="allIssues"
          :updateChoosePlan="updateChoosePlan"
          :dataZip="dataZip?.data"
          :index="index"
      />
      <GiftInformation :errors="errors" :index="index"/>
      <div v-if="index === 0">
        <YourInformation :yourInfo="yourInfo" :errors="errors"/>
      </div>
      <div v-if="index === 0" class="pt-6 pb-[31px] border-b border-b-light-gray">
        <Field
            v-slot="{ field }"
            name="signup"
            type="checkbox"
            :value="true"
            :unchecked-value="false"
        >
          <label class="flex items-center gap-[10px]">
            <input
                type="checkbox"
                v-bind="field"
                :value="true"
                class="w-[25px] h-[25px] rounded-small border border-charcoal cursor-pointer accent-vivid-purple"
            />
            Sign up for Vinderkind emails
          </label>
        </Field>
      </div>
      <DeliverInformation :deliveryInfo="deliveryInfo" :errors="errors"/>
      <button
          v-if="false"
          @click.prevent="addSubscription"
          class="text-vivid-purple text-base sm:text-lg font-bold font-raleway flex items-center gap-[9.5px] mt-[17px]"
      >
        <IconPlus class="[&_path]:fill-vivid-purple"/>
        Add Another Subscription
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, onMounted, ref, type PropType} from 'vue'
import DeliveryZipCode from '@components/sign-up/DeliveryZipCode.vue'
import SubscriptionPreferance from '@components/sign-up/SubscriptionPreferance.vue'
import GiftInformation from '@components/sign-up/GiftInformation.vue'
import YourInformation from '@components/sign-up/YourInformation.vue'
import DeliverInformation from '@components/sign-up/DeliverInformation.vue'
import IconPlus from '@components/icons/IconPlus.vue'
import {useIssuesStore} from '@stores/issues'
import {useTotalPaymentStore} from '@stores/totalPayment'
import {useSignUpCartsStore} from '@stores/sign-up-cart'
import {required} from '@utills/helpers/validation'
import {emailValidation} from '@utills/helpers/validation'
import {fetchData} from '@api/query'
import {Field} from 'vee-validate'
import type {IPlan} from '@utills/types'

export default defineComponent({
  components: {
    DeliveryZipCode,
    SubscriptionPreferance,
    GiftInformation,
    YourInformation,
    DeliverInformation,
    IconPlus,
    Field
  },
  props: {
    errors: {
      type: Object,
      required: true
    },
    addSubscription: {
      type: Function as PropType<(payload?: MouseEvent) => void>,
      required: true
    },
    subscription: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    allSubscription: {
      type: Object,
      required: true
    }
  },
  data(props) {
    return {
      yourInfo: [
        {
          placeholder: 'Full name',
          field: 'name' + '-' + props.index,
          valid: this.required
        },
        {
          placeholder: 'Email',
          field: 'email' + '-' + props.index,
          valid: this.emailValidation
        }
      ],
      deliveryInfo: [
        {
          placeholder: 'Name',
          field: 'delivery_name' + '-' + props.index,
        },
        {
          placeholder: 'Address',
          field: 'address_line_1' + '-' + props.index,
        },
        {
          placeholder: 'Address Line 2',
          field: 'address_line_2' + '-' + props.index,
        },
        {
          placeholder: 'City',
          field: 'city' + '-' + props.index,
        },
        {
          placeholder: 'State',
          field: 'state' + '-' + props.index,
        },
        {
          placeholder: 'Zip Code',
          field: 'zip' + '-' + props.index,
          handler: false
        }
      ],
      zipVal: ''
    }
  },
  methods: {
    updateZipCode(value: string) {
      this.$emit('update:subscription', value)
      this.zipVal = value
      const id = this.index
      if (value.length === 5) {
        fetchData(`zip/${value}`)
            .then((res) => {
              const isErrorResponse = (response: any): response is { err: string } => {
                return response && typeof response.err === 'string';
              };

              if (!isErrorResponse(res)) {
                this.dataZip = res
                this.$emit('enableSection', true)
                // @ts-ignore
                const defaultPlan = res?.data?.filter((it) => it.type === 'yearly')[0]

                // updateChoosePlan
                this.updateChoosePlan(defaultPlan.id, defaultPlan)
                //this.handleZipsPayment({ id, ...defaultPlan })
              } else {
                this.dataZip.err = true
              }
            }).catch(() => {
        })
      } else {
        this.dataZip = []
        this.$emit('enableSection', false)
        this.deleteZip(id)
      }
    },
    removeSubscription() {
      this.$props.allSubscription.splice(this.$props.index, 1)
      this.deleteZip(this.index)
    }
  },
  setup() {
    let dataZip = ref()
    let choosePlan = ref('0')

    const IssuesStore = useIssuesStore()
    const {clearCart} = useSignUpCartsStore()
    const allIssues = ref([])


    onMounted(async () => {
      clearCart()
      await IssuesStore.fetchIssues()
      allIssues.value = IssuesStore.allIssues
    })
    const {totalPayment, handleZipsPayment, deleteZip} = useTotalPaymentStore()
    const updateChoosePlan = (planId: number, plan: Object) => {
      deleteZip(planId)
      handleZipsPayment({
        id: planId,
        ...plan
      })

      choosePlan.value = dataZip?.value?.data.findIndex((plan: IPlan) => plan.id == planId)
    }

    return {
      choosePlan,
      allIssues,
      updateChoosePlan,
      emailValidation,
      required,
      dataZip,
      totalPayment,
      handleZipsPayment,
      deleteZip,
    }
  }
})
</script>
