import { defineStore, type StoreDefinition } from 'pinia'
import { ref } from 'vue'
import { fetchData } from '@/api/query';
import type {ICartItem} from '@utills/types'


export const useCartsStore: StoreDefinition<string> = defineStore('carts', () => {
  const carts = ref<ICartItem[]>([])
  const tax = ref(0)
  const shipping = ref(0)
  const spinnings = ref<number[]>([])
  const discount = ref(0)
  const total = ref(0)


  const fetchCarts = async () => {
    try {
      const response:any = await fetchData('cart', 'get');
      response?.data.products.forEach((product: ICartItem) => {
        const cart = carts.value.find((cart) => cart.id === product.id)
        if (cart) {
          cart.count = product.count
        } else {
          carts.value.push(product)
        }
      })

      //todo, maybe we need to do it, maybe not
      // when user refreshs the page we start with clean slate
      // when user clicks on remove, we remove it manually
      //remove stuff not in products

      tax.value = response.data.tax

      total.value = response.data.total

      // no cycle
      // cycle.value = response.data.cycle?.price ?? null

      shipping.value = response.data.shipping

      discount.value = response.data.discountTotal

      clearSpinnings()
    } catch (error) {
      console.error('Error fetching accessories:', error);
    }
  };

  fetchCarts()

  const setDiscount = async (discount: any) => {
    const response:any = await fetchData('cart/setDiscount', 'post', {
      discount,
    });
    fetchCarts()
    return response
  }

  const setShipment = async (postcode: any) => {
    const response:any = await fetchData('cart/setShipping', 'post', {
      postcode,
    });
    fetchCarts()
    return response
  }

  const updateCount = async (cartId: number, increment = true) => {
    addtoSpinning(cartId)
    await fetchData('cart/incrementItem', 'post', {
      product_id: cartId,
      increment
    });
    const cart = carts.value.find((cart) => cart.id === cartId)
    if (cart) {
      if (increment) {
        cart.count++
      } else {
        if (cart.count > 1) {
          cart.count--
        } else {
          removeCart(cartId)
          cart.count = 0
        }
      }
    }
    fetchCarts()
  }

  const addCart = (cart: ICartItem, increment = true) => {
    // addtoSpinning(cart.id)
    const existingCart = carts.value.find((c) => c.id === cart.id)
    if (increment) {
      if (existingCart) {
        updateCount(cart.id)
      } else {
        carts.value.push(cart)
        updateCount(cart.id)
      }
    } else {
      updateCount(cart.id, false)
    }
    // fetchCarts()
  }

  const removeCart = async (cartId: number) => {
    addtoSpinning(cartId)
    await fetchData('cart/remove', 'post', {
      product_id: cartId,
    });
    const index = carts.value.findIndex((cart) => cart.id === cartId)
    if (index !== -1) {
      carts.value.splice(index, 1)
    }
    fetchCarts()
  }

  const getCount = (cart: ICartItem) => {
    const existingCart = carts.value.find((c) => c.id === cart.id)
    if (existingCart) {
      return existingCart.count;
    } else {
      return 0;
    }
  }

  const addtoSpinning = (cartId: number) => {
    spinnings.value.push(cartId)
  }

  const getSpinning = (cartId: number) => {
    return spinnings.value.find((c) => c == cartId)
  }

  const clearSpinnings = () => {
    spinnings.value.splice(0, spinnings.value.length)
  }

  const clearCart = async () => {
    await fetchData('cart/clear', 'post', { });
    carts.value.splice(0, carts.value.length)
    fetchCarts()
  }

  const calculateTax = () => {
    return Number(tax.value)
  }

  const calculateTotal = () => {
    return Number(total.value)
  }

  const calculateDiscount = () => {
    return Number(discount.value)
  }


  const calculateCycle = () => {
    return Number(0);
  }


  const calculateShipping = () => {
    return Number(shipping.value)
  }

  const getCarts = () => {
    return carts.value
  }

  return { 
    carts,
    tax,
    shipping,
    setDiscount,
    setShipment,
    updateCount,
    removeCart,
    calculateTotal,
    calculateDiscount,
    calculateShipping,
    calculateCycle,
    calculateTax,
    addCart,
    clearCart,
    getCount,
    getSpinning,
    getCarts,
    fetchCarts,
    spinnings,
    discount,
    total
  }
})
