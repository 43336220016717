<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.488"
    height="29.131"
    viewBox="0 0 25.488 29.131"
  >
    <path
      id="Icon_awesome-play"
      data-name="Icon awesome-play"
      d="M24.146,12.216,4.119.376A2.716,2.716,0,0,0,0,2.726V26.4a2.728,2.728,0,0,0,4.119,2.35L24.146,16.915a2.728,2.728,0,0,0,0-4.7Z"
      transform="translate(0 -0.002)"
      fill="#9617c7"
    />
  </svg>
</template>
