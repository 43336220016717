<template>
  <Information></Information>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import Information from "@components/account/Information.vue"

export default defineComponent({
  components: {
    Information
  }
})
</script>

