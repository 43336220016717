<template>
  <CustomForm v-slot="{ errors }" @submit="onSubmit">
    <div class="pt-9 pb-[45px] pl-[33px] pr-[48px] min-w-[80vw] sm:min-w-[605px] w-full">
      <h5 class="text-[19px]/[22px] sm:text-[27px]/[31px] font-semibold font-raleway mb-4">
        Delivery info
      </h5>
      <div class="flex flex-col gap-4">
        <div
            v-for="(field, index) in yourInfo"
            :key="index"
            class="w-full flex flex-col justify-center relative"
        >
          <CustomField
              :name="field.field"
              :rules="[field.valid]"
              :placeholder="field.placeholder"
              :type="field.type"
              :modelValue="field.value"
          />
          <span></span>
          <span class="text-red-500 text-xs pt-1">{{ errors[field.field] }}</span>
        </div>
      </div>
      <button
          class="mt-4 flex justify-center bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
          :disabled="spinning"
      >
        <span
            v-if="spinning"
            class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
        >
          <CustomLoader/>
        </span>
        <span v-else>
          Update information
        </span>
      </button>
    </div>
  </CustomForm>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Form as CustomForm} from 'vee-validate'
import {required} from '@utills/helpers/validation'
import CustomField from '@components/reusable/CustomField.vue'
import {useUser} from "@stores/auth";
import CustomLoader from "@components/reusable/CustomLoader.vue";

export default defineComponent({
  components: {CustomLoader, CustomForm, CustomField},
  props: {
    id: {
      type: Number,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },

  setup(props, {emit}) {
    const {requestSubscriptionUpdateHandler} = useUser();
    const spinning = ref<boolean>(false)

    const yourInfo = [
      {
        placeholder: 'Name',
        field: 'name',
        valid: required,
        type: 'input',
        value: props.delivery.name ?? ''
      },
      {
        placeholder: 'Address line 1',
        field: 'address_line_1',
        valid: required,
        type: 'input',
        value: props.delivery.address_line_1 ?? ''
      },
      {
        placeholder: 'Address line 2',
        field: 'address_line_2',
        type: 'input',
        valid: () => true,
        value: props.delivery.address_line_2 ?? ''
      },
      {
        placeholder: 'City',
        field: 'city',
        type: 'input',
        valid: required,
        value: props.delivery.city ?? ''
      },
      {
        placeholder: 'State',
        field: 'state',
        type: 'input',
        valid: required,
        value: props.delivery.state ?? ''
      },
      {
        placeholder: 'Postal code',
        field: 'postal_code',
        valid: required,
        type: 'input',
        value: props.delivery.postal_code ?? ''
      },
    ]


    const onSubmit = async (values: { [key: string]: string }) => {
      spinning.value = true
      await requestSubscriptionUpdateHandler(props.id, values);
      spinning.value = false
      emit('close')
    }

    return {
      spinning,
      yourInfo,
      required,
      onSubmit
    }
  }
})
</script>