<template>
  <button
    @click.prevent="toggleDivVisibility"
    class="font-raleway flex gap-3 items-center pl-[17px] pr-[54px] mt-[28px] cursor-pointer py-2 w-max bg-lavender rounded-[18px] text-vivid-purple relative"
  >
    <IconCoupon />
    <span class="text-[13px] sm:text-base">I have a promo/referral code</span>
    <img
      src="@assets/images/purple-arrow.png"
      alt="arrow"
      class="absolute w-[13px] h-2 right-5"
      draggable="false"
      :class="!isDivVisible ? 'rotete-180' : '-rotate-180'"
    />
  </button>
  <div v-if="isDivVisible" class="flex flex-col mt-[17px] relative">
    <CustomField 
      @input=" (e: Event) => { promo = (e.target as HTMLInputElement)?.value } "
      name="promocode"
      :rules="[required]"
      placeholder="Promo Code"
    />
    <button
      @click.prevent="applyDiscount" class="absolute top-[14px] right-4 text-lg text-vivid-purple">
      Apply
    </button>
   <span v-if="promorErr.length" class="text-red-500 text-xs">{{ promorErr }}</span> 
   <span v-if="promoText.length" class="text-green-500 text-xs">{{ promoText }}</span> 
  </div>
  <h5 class="text-[19px]/[22px] sm:text-[27px]/[31px] font-semibold mt-[17px] mb-[13px]">
    Payment information
  </h5>
  <div class="flex flex-col relative">
    <div class="mb-4">
      <CustomField name="cartName" :rules="[required]" placeholder="Name on card" />
      <span class="text-red-500 text-xs pt-1">{{ errors.cartName }}</span>
    </div>
    <div class="relative">
      <CustomField
        v-model="cardNumber"
        name="cardNumber"
        :value="cardNumber"
        :rules="[required, cardValidation]"
        placeholder="Card number"
        v-mask="cardMask"
      />
      <img
        v-if="cardLogoUrl"
        :src="cardLogoUrl"
        alt="Card Logo"
        class="absolute top-1/2 right-5 transform -translate-y-1/2 w-12"
      />
    </div>
    <span class="text-red-500 text-xs pt-1">{{ errors.cardNumber }}</span>
  </div>
  <div
    class="mt-[17px] h-[49px] sm:h-[53px] flex gap-1 sm:grid grid-cols-3 border border-charcoal justify-between rounded-small px-5 pt-4 pb-[13px] w-full text-xl/6 placeholder:text-silver focus:outline-vivid-purple"
  >
    <div
      v-for="(field, index) in paymentInfo"
      :key="index"
      class="w-max sm:w-full flex flex-col justify-center relative"
    >
      <div>
        <Field
          :name="field.field"
          :rules="[required]"
          :placeholder="field.placeholder"
          class="text-base sm:text-xl placeholder:text-silver focus:outline-none h-full w-full"
          @blur="focusField"
          @focus="focusField"
          v-mask="field.mask"
        />
        <span
          :class="isFocused ? 'opacity-100 z-[1]' : ' opacity-0 z-0'"
          class="absolute bg-white -top-[23px] left-0 px-1 transition-all duration-75 ease-linear text-sm whitespace-nowrap"
          >{{ field.placeholder }}</span
        >
      </div>
      <span class="text-red-500 text-xs pt-1 absolute -bottom-8 whitespace-nowrap">{{
        errors[field.field] ? 'Field is required' : ''
      }}</span>
    </div>
  </div>
  <div 
  class="flex flex-col justify-between bg-lavender rounded-small py-[14px] px-[22px] mt-6">
    <div class="flex justify-between">
      <span class="text-base sm:text-xl/6"
        >Total Payment ${{ Number(calculateTotal()).toFixed(2) }}</span
      >
      <button @click.prevent="toggleViewMore" class="text-[15px] text-vivid-purple font-raleway">
        {{ !viewMore ? 'View Details' : 'Hide Details' }}
      </button>
    </div>
    <div v-if="viewMore" class="mt-4">
      <div class="flex flex-col gap-3">
        <div v-if="calculateCycle()" class="flex justify-between text-base items-center">
          <span>Vinderkind Subscription</span>
          <span>${{ Number(calculateCycle()).toFixed(2) }}</span>
        </div>
        <div v-if="getCarts().length">
          <div v-for="(item, index) in getCarts()" :key="index">
            <div class="flex justify-between text-base items-center">
              <span>{{ item.name }}</span>
              <span>${{ Number(item.price).toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <div v-if="calculateShipping()">
          <div class="flex justify-between text-base items-center">
            <span>Shipping</span>
            <span>${{ Number(calculateShipping()).toFixed(2) }}</span>
          </div>
        </div>
        <div v-if="!calculateShipping() && page === 'checkout'">
          <div class="flex justify-between text-base items-center">
            <span>Shipping</span>
            <span>Free</span>
          </div>
        </div>

        <div v-if="calculateTax()">
            <div class="flex justify-between text-base items-center">
              <span>Tax</span>
              <span>${{ Number(calculateTax()).toFixed(2) }}</span>
            </div>
        </div>
        
        <div v-if="calculateDiscount()">
            <div class="flex justify-between text-base items-center">
              <span>Discount</span>
              <span>-${{ Number(calculateDiscount()).toFixed(2) }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import IconCoupon from '@components/icons/IconCoupon.vue'
import { Field } from 'vee-validate'
import CustomField from '@components/reusable/CustomField.vue'
import { required } from '@utills/helpers/validation'
import { useTotalPaymentStore } from '@stores/totalPayment'
import { useSignUpCartsStore } from '@stores/sign-up-cart'
import { useCartsStore } from '@stores/carts'

import cardLogo from '@utills/helpers/constant'
import type {IResponseDiscount} from "@utills/types";

export default defineComponent({
  components: { IconCoupon, Field, CustomField },
  data() {
    return {
      expiryDate: '',
    }
  },
  props: {
    errors: {
      type: Object,
      required: true
    },
    paymentInfo: {
      type: Object,
      required: true
    },
    page: {
      type: String,
    }
  },
  setup(props) {
    let func = props.page == 'checkout' ? useCartsStore() : useSignUpCartsStore();
    const { calculateDiscount, calculateCycle, calculateShipping, calculateTax, calculateTotal, getCarts, setDiscount } = func
    
    const cardValidation = (value: string): boolean | string => {
      const cleanedValue = value.replace(/\D/g, '')
      const regex = /^\d{15,16}$/
      return regex.test(cleanedValue) ? true : 'Invalid card'
    }
    const cardNumber = ref('')

    const isDivVisible = ref(false)
    const viewMore = ref(false)
    const promo = ref('')
    const promorErr = ref('')
    const promoText = ref('')

    function toggleDivVisibility() {
      isDivVisible.value = !isDivVisible.value
    }

    const applyDiscount = () =>  {
      setDiscount(promo.value)
        .then(function (response: IResponseDiscount) {
          if(response.err) {
            promorErr.value = 'Not a valid Promo Code'
            promoText.value = ''
          } else {
            promoText.value = '$' + Number(response.data.discountTotal).toFixed(2) + ' Discount Applied'
            promorErr.value = ''
          }
        })
    }
    
    const { calculateTotalAmount, totalPayment, calculateProduct, calculateZip } =
      useTotalPaymentStore()
    function toggleViewMore() {
      viewMore.value = !viewMore.value
    }
    const isFocused = ref(false)

    function focusField() {
      isFocused.value = true
    }
    
    const phoneNumber = ref('')

    const cardLogoUrl = computed(() => {
      const number = cardNumber.value.replace(/\D/g, '')
      for (const card of cardLogo) {
        if (number.match(card.re)) {
          return card.logo
        }
      }
      return ''
    })
    
    const cardMask = computed(() => {
      const number = cardNumber.value.replace(/\D/g, '')
      for (const card of cardLogo) {
        if (number.match(card.re)) {
          return card?.mask ? card.mask : '#### #### #### ####'
        }
      }
      return '#### #### #### ####'
    })

    return {
      isDivVisible,
      promo,
      promorErr,
      promoText,
      toggleDivVisibility,
      required,
      viewMore,
      toggleViewMore,
      focusField,
      isFocused,
      applyDiscount,
      calculateTotalAmount,
      totalPayment,
      calculateProduct,
      calculateZip,
      calculateTax,
      calculateShipping,
      calculateDiscount,
      calculateTotal,
      cardValidation,
      calculateCycle,
      getCarts,
      setDiscount,
      phoneNumber,
      cardNumber,
      cardLogoUrl,
      cardMask
    }
  }
})
</script>
