<template>
  <div class="flex flex-col items-center flex-1">
    <div class="shadow-md border border-gray-200 w-full p-10">
      <div class="flex justify-between">
        <div class="text-lg font-bold">Your information:</div>
        <div>
          <button class="text-vivid-purple text-md" data-modal-target="user-update-modal"
                  data-modal-toggle="user-update-modal"
                  @click="openModal">Edit Your Info
          </button>
        </div>
      </div>
      <div v-if="loggedIn">
        <div>
          {{ user?.name }}
        </div>
        <div>
          {{ user?.email }}
        </div>
        <div>
          {{ user?.phone }}
        </div>
        <div>
          Preferred {{ user?.preferred_contact_method }} contact method
        </div>
      </div>
      <div v-else>
        <p>Loading user information...</p>
      </div>
    </div>
  </div>
  <SharedModal v-model="open">
    <UserUpdateModal @close="closeModal" :user="user"/>
  </SharedModal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import SharedModal from "@components/reusable/SharedModal.vue";
import UserUpdateModal from '@components/account/modal/UserUpdateModal.vue';
import {useAuth} from 'vue3-auth'
import type {IAuth} from '@utills/types'

export default defineComponent({
  components: {UserUpdateModal, SharedModal},
  setup() {
    const {user, loggedIn} = useAuth() as { user: { value: IAuth }, loggedIn: { value: boolean } }
    const userData = ref<IAuth>(user.value)

    onMounted(() => {
      userData.value = user.value
    })

    watch(() => user.value, () => {
      userData.value = user.value
    })

    const open = ref(false);
    const openModal = () => {
      open.value = true;
    };
    const closeModal = () => {
      open.value = false;
    };

    return {
      user: userData, loggedIn, open, openModal, closeModal
    }
  },
});
</script>
