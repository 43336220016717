<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.044"
    height="17.219"
    viewBox="0 0 21.044 17.219"
  >
    <path
      id="np_check-mark_888686_000000"
      d="M23.658,45.551l-5.74-5.74L19.831,37.9l3.827,3.827L37.05,28.332l1.913,1.914Z"
      transform="translate(-17.918 -28.332)"
      fill="#9617c7"
    />
  </svg>
</template>
