import router from "@/router";
import axios from "axios";

export default {
    router,
    fetch: axios,
    baseUrl: import.meta.env.VITE_API_URL,
    redirect: {
        login: '/sign-in',
        logout: '/sign-in',
        home: '/account',
    },
    local: {
        endpoints: {
            login: {
                url: `${import.meta.env.VITE_API_URL}auth/code/verify`,
                method: 'post',
                propertyName: 'token',
            },
            user: {
                url: `${import.meta.env.VITE_API_URL}auth/user`,
                method: 'get',
                propertyName: 'data',
            },
            logout: { url: `${import.meta.env.VITE_API_URL}auth/logout`, method: 'post' },
        },
        token: {
            property: 'token',
            type: 'Bearer',
            name: 'Authorization',
        },
        user: {
            propertyInLogin: 'user',
            propertyInFetch: '',
            propertyRole: 'role',
            propertyPermission: 'permissions',
            autoFetch: true,
        },
    },
}