<template>
  <div class="flex flex-col gap-3 pr-8">
    <router-link to="/account/subscriptions"
                 :class="{ 'bg-lavender': $route.path.includes('/account/subscriptions') }"
                 activeClass="bg-lavender"
                 class="text-nowrap py-2 font-bold rounded-full px-[22px] [&_span]:text-black lg:flex hover:bg-lavender transition-all duration-300 ease-in d-flex items-center justify-content-start">
      <IconUserSubscription
          class="group-hover:[&_path]:stroke-lavender [&_path]:transition-all [&_path]:ease-linear [&_path]:duration-300 mr-2"
      />
      <span>Your subscriptions</span>
    </router-link>
    <router-link to="/account/information"
                 activeClass="bg-lavender"
                 class="text-nowrap py-2 font-bold rounded-full px-[22px] [&_span]:text-black lg:flex hover:bg-lavender transition-all duration-300 ease-in d-flex items-center justify-content-start">

      <IconUserInformation
          class="group-hover:[&_path]:stroke-lavender [&_path]:transition-all [&_path]:ease-linear [&_path]:duration-300 mr-2"
      />
      <span>Your Information</span>
    </router-link>

    <button class="text-nowrap py-2 font-bold rounded-full px-[22px] [&_span]:text-black lg:flex hover:bg-lavender transition-all duration-300 ease-in d-flex items-center justify-content-start"
      @click="logout"
    >
      <IconExit
          class="group-hover:[&_path]:stroke-lavender [&_path]:transition-all [&_path]:ease-linear [&_path]:duration-300 mr-2"
      />
      <span>Logout</span>
    </button>
  </div>
</template>

<script lang="ts">
import { useAuth } from 'vue3-auth'
import IconUserInformation from "@components/icons/IconUserInformation.vue";
import IconUserSubscription from "@components/icons/IconUserSubscription.vue";
import IconExit from "@components/icons/IconExit.vue";

export default {
  components: {IconExit, IconUserSubscription, IconUserInformation},
  data() {
    return {
      links: [
        {title: 'Your subscriptions', link: '/account/subscriptions', type: 'link'},
        {title: 'Your Information', link: '/account/information', type: 'link'},
      ],
    }
  },
  setup() {
    const {logout} = useAuth()

    return {
      logout
    }
  }
}
</script>
