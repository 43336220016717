<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23">
    <g id="Group_26" data-name="Group 26" transform="translate(-23 -27)">
      <rect
        id="Rectangle_97"
        data-name="Rectangle 97"
        width="33"
        height="3"
        rx="1.5"
        transform="translate(23 27)"
        fill="#fff"
      />
      <rect
        id="Rectangle_98"
        data-name="Rectangle 98"
        width="33"
        height="3"
        rx="1.5"
        transform="translate(23 37)"
        fill="#fff"
      />
      <rect
        id="Rectangle_99"
        data-name="Rectangle 99"
        width="33"
        height="3"
        rx="1.5"
        transform="translate(23 47)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
