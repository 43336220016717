<template>
  <SignInContent />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import SignInContent from '@components/sign-in/SignInContent.vue'
export default defineComponent({
  components: { SignInContent }
})
</script>

