<template>
  <div class="shadow-md border border-gray-200 w-full p-10 mb-3" v-if="subscription">
    <div class="flex justify-between">
      <div class="flex-1">
          <span class="text-lg text-black  font-medium me-2 px-5 py-1 rounded-full bg-[#E2E2E2]"
                v-if="!subscription.isActive">
            Inactive
          </span>
        <span class="text-lg text-black  font-medium me-2 px-5 py-1 rounded-full bg-[#D2FACF]"
              v-if="subscription.isActive">
            Active
          </span>
      </div>

      <div>
        Subscription #{{ subscription.id }}
      </div>
    </div>
    <div v-if="!subscription.isActive && isSingle" class="my-5 p-3 rounded flex items-center bg-red-100">
      <IconRenew/>
      <span class="ml-1 text-black text-base pr-3">Subscription ended on {{ subscription.finish_print }}</span>
      <a href="#" class="text-base text-violet-600" v-if="subscription.isRenew" @click="openRenewModal">Renew Now</a>
    </div>
    <div class="flex justify-between items-center my-3">
      <div class="flex-1">
        <div class="text-2xl font-bold">{{ subscription.cycle.name }}</div>
        <div class="text-xs font-light text-gray-700 py-1">{{ subscription.publications }} Publications</div>
        <div class="text-base font-light flex justify-start">
          <IconCalendar/>
          <span class="ml-2">{{ subscription.date_print }}</span>
        </div>
        <div v-if="!subscription.isActive && !isSingle" class="mt-2 flex items-center">
          <IconRenew/>
          <span class="ml-1 text-red-600 text-xs pr-3">Subscription ended on {{ subscription.finish_print }}</span>
          <a href="#" class="text-xs text-violet-600" v-if="subscription.isRenew" @click="openRenewModal">Renew Now</a>
        </div>
      </div>
      <div>
        <span class="text-2xl font-bold">${{ subscription.total }}</span>
      </div>
    </div>
    <div class="border-solid border-gray-200 border-t text-center pt-5 font-bold text-xl"
         v-if="!isSingle && subscription.renew">
      <router-link :to="`/account/subscriptions/${subscription.id}`">
        <span>Manage</span>
      </router-link>
    </div>
    <div class="border-solid border-gray-200 border-y " v-if="isSingle">
      <div class="flex justify-between items-start my-3">
        <div class="flex-1">
          <div class="text-xl font-bold">Delivery information</div>
          <div>{{ subscription.delivery.name }}</div>
          <div>{{ subscription.delivery.address_line_1 }}</div>
          <div v-if="subscription.delivery.address_line_2">{{ subscription.delivery.address_line_2 }}</div>
          <div>{{ subscription.delivery.city }}</div>
          <div>{{ subscription.delivery.state }}</div>
          <div>{{ subscription.delivery.postal_code }}</div>
        </div>
        <div>
          <button @click="openDeliveryEditModal" class="text-black">Edit delivery info</button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-start w-full py-4 text-xl font-bold" v-if="isSingle">Subscription timeline</div>
  <div class="shadow-md border border-gray-200 w-full p-10 mb-3" v-if="subscription && isSingle" v-for="order in subscription.orders">
    <div class="flex justify-between">
      <div class="flex-1">
        <div class="text-base font-bold">{{order.date}}</div>
        <div class="text-xs">Subscribed</div>
      </div>
      <div class="text-right">
        <div class="text-base font-bold">Paid ${{order.total}}</div>
        <div class="text-xs" v-if="subscription.credit_card">with card ending in {{subscription.credit_card.last_four}}</div>
      </div>
    </div>
  </div>

  <SharedModal v-model="openRenew" v-if="subscription">
    <RenewSubscriptionModal @close="closeRenewModal" :subscription="subscription"/>
  </SharedModal>
  <SharedModal v-model="openDeliveryEdit" v-if="subscription">
    <DeliveryUpdateModal @close="closeDeliveryEditModal" :delivery="subscription.delivery" :id="subscription.id"/>
  </SharedModal>

</template>
<script lang="ts">
import {defineComponent, ref} from "vue";
import IconCalendar from "@components/icons/IconCalendar.vue";
import IconRenew from "@components/icons/IconRenew.vue";
import SharedModal from "@components/reusable/SharedModal.vue";
import UserUpdateModal from "@components/account/modal/UserUpdateModal.vue";
import RenewSubscriptionModal from "@components/account/modal/RenewSubscriptionModal.vue";
import DeliveryUpdateModal from "@components/account/modal/DeliveryUpdateModal.vue";

export default defineComponent({
  components: {DeliveryUpdateModal, RenewSubscriptionModal, UserUpdateModal, SharedModal, IconCalendar, IconRenew},
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    subscription: {
      type: Object,
      required: true
    }
  },
  setup() {
    const openRenew = ref(false);
    const openDeliveryEdit = ref(false);
    const openRenewModal = () => {
      openRenew.value = true;
    };
    const closeRenewModal = () => {
      openRenew.value = false;
    };
    const openDeliveryEditModal = () => {
      openDeliveryEdit.value = true;
    };
    const closeDeliveryEditModal = () => {
      openDeliveryEdit.value = false;
    };

    return {
      openRenew, openRenewModal, closeRenewModal,
      openDeliveryEdit, openDeliveryEditModal, closeDeliveryEditModal
    }
  }
})
</script>