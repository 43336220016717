<template>
  <div class="m-auto flex flex-col pb-[66px] lg:pb-[220px] px-5 lg:px-0">
    <AccountTitle/>
    <div class="py-7 px-[23px] pr-4 flex justify-between container lg:py-5 lg:px-6 relative">
      <AccountNavigation></AccountNavigation>
      <RouterView :key="$route.path"/>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import AccountTitle from "@components/account/AccountTitle.vue"
import AccountNavigation from "@components/navigations/AccountNavigation.vue";
import Information from "@components/account/Information.vue"

export default defineComponent({
  components: {
    AccountNavigation,
    AccountTitle,
    Information
  },
})
</script>

