<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.224"
    height="22.224"
    viewBox="0 0 22.224 22.224"
  >
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3 -3)">
      <path
        id="Path_1540"
        data-name="Path 1540"
        d="M21.036,12.768A8.268,8.268,0,1,1,12.768,4.5a8.268,8.268,0,0,1,8.268,8.268Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#9617c7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_1541"
        data-name="Path 1541"
        d="M29.471,29.471l-4.5-4.5"
        transform="translate(-6.368 -6.368)"
        fill="none"
        stroke="#9617c7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>
