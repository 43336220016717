<template>
  <div class="container">
    <h2 class="text-vivid-purple text-[50px] font-hebrew font-bold px-10 m-auto text-center py-10">
      חודש’ליכע פרישע סחורה, צו הערן און ליינען מיט וואונדער
    </h2>
    <div dir="rtl" class="hidden lg:block">
      <ul class="list-disc text-[19px]/[28px] font-hebrew font-normal mt-5">
        <li>חודש’ליך דערליווערט א רייכע USB קאסעטע פון 2 שעה הערן מיט אינטערעסאנטע תוכן</li>
        <li>באגלייט מיט א מייסטערהאפטיגע אויסגאבע וואס מאכט דעם קאסעטע פיל אינטערעסאנטער</li>
        <li>כסדר'דיגע הערליכע תוכן און שיינע מתנות</li>
        <li>מעשיות, ניגונים, פארשטעלונגען, און שעות פון ערליכע געשמאקע אינטערהאלטונג</li>
        <li>איבערראשענדע פראדוקטן פון צייט צו צייט</li>
      </ul>
    </div>
  </div>
</template>
