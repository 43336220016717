<template>
  <div>
    <div class="container pt-[31px] sm:pt-[61px] pb-[30px] sm:pb-[30px] px-[23px] xl:px-0">
      <div class="flex flex-col items-center gap-1">
        <h5 class="text-vivid-purple font-hebrew text-[29px]/[20px] font-bold sm:font-semibold">
          Account
        </h5>
        <span class="text-light-purple text-[17px]/[20px] font-raleway font-semibold">{{ userName }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useAuth} from 'vue3-auth'
import type {IAuth} from "@utills/types";

export default defineComponent({
  setup() {
    const {user} = useAuth() as { user: { value: IAuth } }
    const userName = ref<string>(user.value.name)

    onMounted(() => {
      userName.value = user.value.name
    })

    watch(() => user.value, () => {
      userName.value = user.value.name
    })

    return {
      userName
    }
  },
});
</script>
