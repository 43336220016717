<template>
  <div
    class="m-auto flex flex-col pt-[31px] sm:pt-[66px] pb-[66px] lg:pb-[220px] max-w-[710px] px-5 lg:px-0"
  >
    <div class="flex flex-col justify-center items-center lg:gap-4 pb-10 sm:pb-[70px]">
      <h2
        class="text-vivid-purple text-[29px] sm:text-[50px] lg:text-[65px]/[38px] font-hebrew font-bold pb-2"
      >
        !לאמיר זיין בקשר
      </h2>
      <h5 class="text-vivid-purple text-xl lg:text-[32px]/[38px] font-bold font-sans">
        {{email}}
      </h5>
      <h5 class="text-vivid-purple text-xl lg:text-[32px]/[38px] font-bold font-sans">
        {{phone}}
      </h5>
    </div>
    <div>
      <h5 class="text-vivid-purple text-lg lg:text-3xl/[38px] font-bold font-hebrew text-justify pb-1" dir="rtl">
      <span>וואס באקום איך אינעם וואונדערקינד</span><span>?</span>
      </h5>
      <p class="text-base lg:text-[21px]/[30px] font-hebrew text-end font-light">
      <span>
        יעדע ‘וואונדערקינד’ אויסגאבע פארמאגט אין זיך א גאר רייכע
      </span>
      <span>
        USB
      </span>
      <span>
        מיט הערליכע תוכן
      </span>
      <span>
        געשיכטעס, פארשטעלונגען, ניגונים, לעבעדיגע באזוכן, געימס און פיל מער, בייגעלייגט מיט א גאר
        רייכע אויסגאבע וועלכע טוט באגלייטן דעם קאסעטע מיט הערליכע ארטיקלען, מאלערייען, ידיעות און
        קאמיקס
      </span>
      </p>
    </div>
    <div class="pt-10">
      <h5 class="text-vivid-purple text-lg lg:text-3xl/[38px] font-bold font-hebrew text-end pb-1">
        ?וויפיל ‘וואונדערקינדער’ באקום איך מיטן זיך איינשרייבן
      </h5>
      <p class="text-base lg:text-[21px]/[30px] font-hebrew text-end font-light">
        יעדע חודש וועט איר באקומען דעליווערט א פרישע וואונדערקינד פעקל. יעדע סובסקריבשאן נעמט אריין
        12 וואונדערקינדער
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { fetchData } from '@api/query';
export default defineComponent({
  components: {},
    setup() {
      const phone = ref('')
      const email = ref('')
      
      onMounted(async () => {
        const response:any = await fetchData('contact', 'get')
        phone.value = response?.data.phone
        email.value = response?.data.email
      })

      return {
        phone,
        email,
      }
  },
})
</script>
