<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.628" height="3.349" viewBox="0 0 15.628 3.349">
    <path
      id="Icon_awesome-minus"
      data-name="Icon awesome-minus"
      d="M14.512,14.625H1.116A1.116,1.116,0,0,0,0,15.741v1.116a1.116,1.116,0,0,0,1.116,1.116h13.4a1.116,1.116,0,0,0,1.116-1.116V15.741A1.116,1.116,0,0,0,14.512,14.625Z"
      transform="translate(0 -14.625)"
    />
  </svg>
</template>
