import {defineStore} from 'pinia'
import {fetchDataWithError} from "@api/query";
import {useAuth} from "vue3-auth"
import {ref} from "vue";

export const useUser = defineStore('user', () => {
    const {fetchUser} = useAuth();
    const subscriptions = ref<[] | null>([])
    const subscription = ref<object|null>(null)

    const requestUserUpdateHandler = async (values: any) => {
        await fetchDataWithError('auth/user/update', 'post', values);
        await fetchUser()
    }

    const requestUserSubscriptionHandler = async () => {
        const response: any = await fetchDataWithError('auth/subscriptions', 'post')
        if(response.success) {
            subscriptions.value = response.data
        }else{
            subscriptions.value = null
        }

    }

    const requestSubscriptionSingleHandler = async (id: any) => {
        const response:any = await fetchDataWithError(`auth/subscriptions/${id}`, 'get')
        if(response.success) {
            subscription.value = response.data
        }else{
            subscription.value = null;
        }
    }

    const requestSubscriptionUpdateHandler = async (id:number, values:Object) => {
        try {
            await fetchDataWithError(`auth/subscriptions/${id}`, 'put', values)
            await requestSubscriptionSingleHandler(id);
        } catch (e) {
        }
    }

    const requestRenewSubscriptionHandler = async (data: any) => {
        await fetchDataWithError('auth/subscription/renew', 'post', data);
        await requestUserSubscriptionHandler();
    }

    return {
        subscriptions,
        subscription,
        requestUserUpdateHandler,
        requestUserSubscriptionHandler,
        requestRenewSubscriptionHandler,
        requestSubscriptionSingleHandler,
        requestSubscriptionUpdateHandler
    }
})
