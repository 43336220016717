<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.628"
    height="15.628"
    viewBox="0 0 15.628 15.628"
  >
    <path
      id="Icon_awesome-plus"
      data-name="Icon awesome-plus"
      d="M14.512,8.39H9.489V3.366A1.116,1.116,0,0,0,8.372,2.25H7.256A1.116,1.116,0,0,0,6.14,3.366V8.39H1.116A1.116,1.116,0,0,0,0,9.506v1.116a1.116,1.116,0,0,0,1.116,1.116H6.14v5.023a1.116,1.116,0,0,0,1.116,1.116H8.372a1.116,1.116,0,0,0,1.116-1.116V11.739h5.023a1.116,1.116,0,0,0,1.116-1.116V9.506A1.116,1.116,0,0,0,14.512,8.39Z"
      transform="translate(0 -2.25)"
    />
  </svg>
</template>
