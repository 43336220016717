<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="31" viewBox="0 0 34 31">
    <g id="Group_424" data-name="Group 424" transform="translate(-222 -27)">
      <rect
        id="Rectangle_222"
        data-name="Rectangle 222"
        width="34"
        height="31"
        transform="translate(222 27)"
        fill="none"
      />
      <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(230.603 30.5)">
        <path
          id="Path_1535"
          data-name="Path 1535"
          d="M25.048,29.643V27.262A4.762,4.762,0,0,0,20.286,22.5H10.762A4.762,4.762,0,0,0,6,27.262v2.381"
          transform="translate(-6 -8.214)"
          fill="none"
          stroke="#edca0c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1536"
          data-name="Path 1536"
          d="M21.524,9.262A4.762,4.762,0,1,1,16.762,4.5a4.762,4.762,0,0,1,4.762,4.762Z"
          transform="translate(-7.238 -4.5)"
          fill="none"
          stroke="#edca0c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </g>
  </svg>
</template>
