<template>

  <div class="flex-col  flex-1" v-if="subscription">
    <div class="flex flex-col items-center">
      <Subscription :isSingle="true" :subscription="subscription"></Subscription>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent, onMounted, ref, watch} from "vue";
import AccountTitle from "@components/account/AccountTitle.vue"
import AccountNavigation from "@components/navigations/AccountNavigation.vue";
import Subscription from "@components/account/Subscription.vue"
import {useUser} from "@stores/auth";
import {useRoute} from "vue-router";

export default defineComponent({
  components: {
    AccountNavigation,
    AccountTitle,
    Subscription
  },
  setup() {
    const route = useRoute()
    const userStore = useUser();
    const subscription = ref<object | null>(null)

    onMounted(async () => {
      await userStore.requestSubscriptionSingleHandler(route.params.id)
      subscription.value = userStore.subscription
    });

    watch(() => userStore.subscription, (newSubscription) => {
      subscription.value = newSubscription;
    });

    return {subscription}
  }
})
</script>

