<template>
  <div class="pt-9 pb-[45px] pl-[33px] pr-[48px] min-w-[80vw] sm:min-w-[605px] w-full">
    <h5 class="text-[19px]/[22px] sm:text-[24px]/[31px] font-semibold mt-[17px] mb-[13px] text-center">
      Renew your subscription
    </h5>
    <div class="flex flex-col gap-[17px]">
      <div class="w-full flex flex-col justify-center relative  items-center">
        <div class="text-2xl font-bold">{{ subscription.cycle.name }}</div>
        <div class="text-xs font-light text-gray-700 py-1">{{ subscription.publications }} Publications</div>
        <div class="text-base font-light flex justify-start">
          <IconCalendar/>
          <span class="ml-2">{{ subscription.date_print }}</span></div>
      </div>

      <div>
        <button
            class="mt-4 flex justify-center bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
            @click="submit"
            :disabled="spinning"
        >
          <span
              v-if="spinning"
              class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader/>
          </span>
          <span v-else>
            Renew Subscription
          </span>
        </button>
      </div>
      <div class="text-center leading-3">
        <small>By clicking on the subscribe button, you agree with our<br/> terms and conditions & cancelation
          policy.</small>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue'
import CustomField from '@components/reusable/CustomField.vue'
import {required} from '@utills/helpers/validation'
import IconCalendar from "@components/icons/IconCalendar.vue";
import {useUser} from '@stores/auth'
import CustomLoader from "@components/reusable/CustomLoader.vue";

export default defineComponent({
  components: {CustomLoader, IconCalendar, CustomField},
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const spinning = ref<boolean>(false)
    const {requestRenewSubscriptionHandler} = useUser();

    const submit = async () => {
      spinning.value = true
      await requestRenewSubscriptionHandler({
        id: props.subscription.id
      })
      spinning.value = false
      emit('close')
    }
    return {
      spinning,
      required, submit
    }
  }
})
</script>