<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.183"
    height="16.174"
    viewBox="0 0 16.183 16.174"
  >
    <g id="np_coupon_3551535_000000" transform="translate(-16.682 -16.626)">
      <path
        id="Path_48"
        data-name="Path 48"
        d="M17.175,20.138a1.011,1.011,0,0,0,.587,0A9.228,9.228,0,0,0,18,18.95a.969.969,0,0,1,.109-.376.995.995,0,0,1,.451-.446.761.761,0,0,1,.155-.062.937.937,0,0,1,.223-.045,9.44,9.44,0,0,0,1.188-.242.6.6,0,0,0,.044-.3.592.592,0,0,0-.037-.3,9.07,9.07,0,0,0-1.187-.242,1.823,1.823,0,0,0-.373-.019,1.765,1.765,0,0,0-1.644,2.036,9.069,9.069,0,0,0,.242,1.187Z"
        transform="translate(-0.174 -0.217)"
        fill="#9617c7"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M17.424,72.374a1.777,1.777,0,0,0,.727.438,1.635,1.635,0,0,0,.421.076,1.792,1.792,0,0,0,.383-.029,9.071,9.071,0,0,0,1.187-.242,1,1,0,0,0,0-.587,9.23,9.23,0,0,0-1.187-.242.969.969,0,0,1-.376-.109.995.995,0,0,1-.446-.451.761.761,0,0,1-.062-.155.937.937,0,0,1-.045-.223,9.44,9.44,0,0,0-.242-1.188,1.056,1.056,0,0,0-.6,0,8.9,8.9,0,0,0-.242,1.188,1.755,1.755,0,0,0-.02.373,1.784,1.784,0,0,0,.5,1.151Z"
        transform="translate(-0.18 -40.144)"
        fill="#9617c7"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M69.674,72.618a9.054,9.054,0,0,0,1.188.242,1.7,1.7,0,0,0,.373.019,1.765,1.765,0,0,0,1.636-2.048,9.07,9.07,0,0,0-.242-1.187,1,1,0,0,0-.587,0,9.23,9.23,0,0,0-.242,1.187.969.969,0,0,1-.109.376.994.994,0,0,1-.451.446.761.761,0,0,1-.155.062.937.937,0,0,1-.223.045A9.44,9.44,0,0,0,69.674,72a1.062,1.062,0,0,0,0,.6Z"
        transform="translate(-40.114 -40.133)"
        fill="#9617c7"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M69.608,17.714a9.052,9.052,0,0,0,1.188.242.979.979,0,0,1,.221.044,1.025,1.025,0,0,1,.6.516.819.819,0,0,1,.062.155.953.953,0,0,1,.044.223,9.562,9.562,0,0,0,.242,1.187,1.048,1.048,0,0,0,.6,0,8.916,8.916,0,0,0,.242-1.187,1.9,1.9,0,0,0,.02-.374,1.779,1.779,0,0,0-1.261-1.592,1.635,1.635,0,0,0-.421-.076,1.775,1.775,0,0,0-.368.022,9.209,9.209,0,0,0-1.188.242,1.011,1.011,0,0,0,0,.587Z"
        transform="translate(-40.048 -0.171)"
        fill="#9617c7"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        d="M35.376,42.028c-.029.039.034.157.146.269s.242.174.269.146a33.459,33.459,0,0,0,6.669-6.66c.029-.042-.039-.163-.151-.274s-.242-.179-.274-.151a33.674,33.674,0,0,0-6.66,6.669Z"
        transform="translate(-14.157 -14.187)"
        fill="#9617c7"
      />
      <path
        id="Path_53"
        data-name="Path 53"
        d="M32.565,34.79a1.78,1.78,0,0,0,1.262-.579,1.653,1.653,0,0,0,.432-1.226c0-.056-.131-.1-.289-.095s-.289.029-.3.061a.969.969,0,0,1-1.088.727.817.817,0,0,1,0-1.617.969.969,0,0,1,1.076.747c0,.031.143.058.3.061s.289-.039.3-.095v-.014h0a1.673,1.673,0,0,0-.446-1.227A1.786,1.786,0,0,0,32.548,31a1.866,1.866,0,0,0-1.341.528,1.918,1.918,0,0,0,0,2.736,1.863,1.863,0,0,0,1.358.526Z"
        transform="translate(-10.57 -10.889)"
        fill="#9617c7"
      />
      <path
        id="Path_54"
        data-name="Path 54"
        d="M53.579,53.89a1.918,1.918,0,0,0,.014,2.737,1.865,1.865,0,0,0,1.355.516,1.783,1.783,0,0,0,1.263-.579,1.654,1.654,0,0,0,.434-1.217c0-.056-.131-.1-.289-.095s-.289.029-.3.061a.97.97,0,0,1-1.088.74.817.817,0,0,1,0-1.617.97.97,0,0,1,1.078.769c0,.031.143.058.3.061s.291-.061.291-.107v-.014a1.661,1.661,0,0,0-.446-1.226,1.779,1.779,0,0,0-1.275-.567,1.867,1.867,0,0,0-1.345.54Z"
        transform="translate(-27.524 -27.823)"
        fill="#9617c7"
      />
      <path
        id="Path_55"
        data-name="Path 55"
        d="M44.423,17.543a2.83,2.83,0,0,0,2.666,0,1,1,0,0,0,.025-.3.99.99,0,0,0-.029-.284,2.835,2.835,0,0,0-2.666,0,1.762,1.762,0,0,0,0,.587Z"
        transform="translate(-20.993)"
        fill="#9617c7"
      />
      <path
        id="Path_56"
        data-name="Path 56"
        d="M47.091,79.08a1.884,1.884,0,0,0,0-.6,2.835,2.835,0,0,0-2.666,0,1.8,1.8,0,0,0,0,.587,2.83,2.83,0,0,0,2.666.014Z"
        transform="translate(-21 -46.607)"
        fill="#9617c7"
      />
      <path
        id="Path_57"
        data-name="Path 57"
        d="M78.84,44.39a1.056,1.056,0,0,0-.294.025,2.83,2.83,0,0,0,0,2.666,1.884,1.884,0,0,0,.6,0,2.835,2.835,0,0,0,0-2.666,1.021,1.021,0,0,0-.308-.025Z"
        transform="translate(-46.616 -21.032)"
        fill="#9617c7"
      />
      <path
        id="Path_58"
        data-name="Path 58"
        d="M17,47.084a1.848,1.848,0,0,0,.6,0,2.835,2.835,0,0,0,0-2.666,1.762,1.762,0,0,0-.587,0,2.83,2.83,0,0,0,0,2.666Z"
        transform="translate(0 -21.035)"
        fill="#9617c7"
      />
    </g>
  </svg>
</template>
