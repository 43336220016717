<template>
  <div
    class="flex flex-col sm:flex-row relative h-[627px] min-[420px]:h-[700px] sm:h-[571px] bg-golden-yellow sm:bg-transparent"
  >
    <div
      class="hidden items-center justify-end pl-4 lg:pr-32 relative z-[8] bg-golden-yellow rounded-r-[370px] w-[60%] sm:flex"
    >
      <div class="flex flex-col items-center">
        <div>
          <img src="@/assets/images/car.png" alt="car" draggable="false" />
        </div>
        <h2 class="text-[55px]/[56px] text-vivid-purple font-hebrew font-bold max-w-[470px]">
          חודש׳ליך געברעגנט צו אייער טיר
        </h2>
      </div>
    </div>
    <div class="absolute right-0 h-[570px] top-[1px] hidden sm:block w-full">
      <img
        src="@assets/images/home-footer.png"
        alt="home footer image"
        class="h-full object-cover w-full"
        draggable="false"
      />
    </div>
    <div class="block sm:hidden">
      <img
        src="@assets/images/mobile-home-footer.png"
        alt="mobile footer home"
        class="w-full h-[627px] object-cover"
        draggable="false"
      />
    </div>
    <span
      class="text-[35px]/[36px] text-vivid-purple font-bold font-hebrew px-8 text-center absolute bottom-[53px] min-[420px]:bottom-[80px] sm:hidden w-full"
    >
      חודש׳ליך געברעגנט צו אייער טיר
    </span>
  </div>
</template>
