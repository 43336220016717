import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useThankYouStore = defineStore('thankYou', () =>{

  const order = ref({})
  const setOrder = (orderData:any) => {
    order.value = orderData;
  }
  const getOrder = () => {
    return order.value;
  }
  return {setOrder, getOrder}
})
