<template>
  <CheckoutContent />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CheckoutContent from '@components/checkout/CheckoutContent.vue'
export default defineComponent({
  components: { CheckoutContent },
  setup() {
    return {}
  }
})
</script>
