<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 200"
    xml:space="preserve"
  >
    <g><rect x="20.673" y="25" width="25" height="130" fill="#9617C7" /></g>
    <g><rect x="70.488" y="25" width="25" height="130" fill="#9617C7" /></g>
  </svg>
</template>
