<template>
  <div class="mt-[15px] sm:mt-[16px]">
    <label for="email">Choose your first publication on this subscription</label>
    <Field :name="'startMonth' + '-' + index" as="select"
           class="w-full border rounded-small shadow-gray pt-4 pb-[13px] px-[18px] text-base sm:text-xl border-charcoal placeholder:text-silver h-[49px] sm:h-[53px]">
      <option value="" :selected="true">Select A Date</option>
      <option v-for="item in hebDatesSelect" :key="item.id" :value="item.id">{{ item.label }}</option>
    </Field>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import SharedSelect from "@components/reusable/SharedSelect.vue";
import {useSignUpCartsStore} from "@stores/sign-up-cart";
import {Field} from 'vee-validate'

export default defineComponent({
  components: {SharedSelect, IconCalendar, Field},
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  setup() {
    const isDivVisible = ref(false)
    const selectedSubscriptionType = ref('')
    const hebDatesSelect = ref<any>([])
    const signUp = useSignUpCartsStore()

    onMounted(async () => {
      await signUp.fetchHebDates()
      signUp.hebSelect.forEach((date :any) =>
          hebDatesSelect.value.push({
              id: `${date.day}_${date.month}_${date.year}`,
              label: date.name
          })
      );
    })

    function toggleDivVisibility() {
      isDivVisible.value = !isDivVisible.value
    }

    return {
      isDivVisible,
      selectedSubscriptionType,
      toggleDivVisibility,
      hebDatesSelect
    }
  }
})
</script>
