<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="31" viewBox="0 0 34 31">
    <g id="Group_423" data-name="Group 423" transform="translate(-177 -27)">
      <rect
        id="Rectangle_223"
        data-name="Rectangle 223"
        width="34"
        height="31"
        transform="translate(177 27)"
        fill="none"
      />
      <g
        id="Icon_feather-shopping-cart"
        data-name="Icon feather-shopping-cart"
        transform="translate(180.349 30.5)"
      >
        <path
          id="Path_1537"
          data-name="Path 1537"
          d="M14.381,31.19A1.19,1.19,0,1,1,13.19,30,1.19,1.19,0,0,1,14.381,31.19Z"
          transform="translate(-3.667 -7.381)"
          fill="none"
          stroke="#edca0c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1538"
          data-name="Path 1538"
          d="M30.881,31.19A1.19,1.19,0,1,1,29.69,30,1.19,1.19,0,0,1,30.881,31.19Z"
          transform="translate(-7.071 -7.381)"
          fill="none"
          stroke="#edca0c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1539"
          data-name="Path 1539"
          d="M1.5,1.5H6.262l3.19,15.94a2.381,2.381,0,0,0,2.381,1.917H23.4a2.381,2.381,0,0,0,2.381-1.917l1.9-9.988H7.452"
          transform="translate(-1.5 -1.5)"
          fill="none"
          stroke="#edca0c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </g>
  </svg>
</template>
